import moment from "moment"
import axios from "axios"

import {
  // suscripcionVigente,
  // tieneSuscripcionVencida,
  // getSuscripcionVigente,
  // getSuscripcionVencimientoMes,
  validarSuscripcionBase,
  validarSuscripcionPagoBase,
  validarSuscripcionGratuitaBase,
  getSuscripcionVigenteBase,
  getSuscripcionVencimientoMesBase,
  tieneSuscripcionVencidaBase,
} from "acciondigital-commons/suscripciones-commons"
import {
  getAniomesActual as getAniomesActual_,
  getAniomesAjuste as getAniomesAjuste_,
  getAniomesdiaActual as getAniomesdiaActual_,
} from "acciondigital-commons/commonUtils"
import {
  getMembresiaVigente_COMPRADOR_MAYORISTA,
  getSuscripcionVencimientoMesAF,
  suscripcionesDisponiblesAccionFerretera,
  tieneSuscripcionVencidaAF,
  validarMembVigenteCompMayorista,
  validarSuscripcionVigenteAF,
} from "acciondigital-commons/accionferretera-commons"

export const enviarCliq = (apiBase, mensaje) => {
  const options = {
    params : {mensaje}
  }
  return axios.get(`${apiBase}enviarCliq`, options).then(res => res.data)
}

/*
export const validarAccesoSusVigenteAF = ({
  infoUsuarios: { suscripciones: susObj } = {},
}) => validarSuscripcionVigenteAF(susObj)
 */
export const validarAccesoSusVigenteAF = ({
  userProperties: { tieneSuscripcionVigente } = {},
}) => tieneSuscripcionVigente

export const validarAccesoMembVigenteCompMayorista = ({
  infoUsuarios: { membresias: memsObj } = {},
}) => validarMembVigenteCompMayorista(memsObj)

/* 
export const validarSuscripcionVigenteAF = (susObj) => {
  if(!susObj) return false

  const sus = Array.isArray(susObj) ? susObj : Object.values(susObj)

  // return suscripcionVigente(getSuscripcionVigenteAF(sus))
  const susVigente = getSuscripcionVigenteAF(sus)
  console.log("🚀 ~ validarSuscripcionVigenteAF ~ susVigente:", susVigente)
  
  return !!susVigente?.codigo
}
export const validarSuscripcionAF = codigo => validarSuscripcionBase(suscripcionesDisponiblesAccionFerretera, codigo)
export const validarSuscripcionPagoAF = codigo => validarSuscripcionPagoBase(suscripcionesDisponiblesAccionFerretera, codigo) 
export const validarSuscripcionGratuitaAF = codigo => validarSuscripcionGratuitaBase(suscripcionesDisponiblesAccionFerretera, codigo)

export const getSuscripcionVigenteAF = (susLst = []) => getSuscripcionVigenteBase(suscripcionesDisponiblesAccionFerretera, susLst)

export const getSuscripcionVencimientoMesAF = (susLst = [], ajusteMes = 0) => getSuscripcionVencimientoMesBase(suscripcionesDisponiblesAccionFerretera, susLst, ajusteMes)

export const tieneSuscripcionVencidaAF = (susLst) => tieneSuscripcionVencidaBase(suscripcionesDisponiblesAccionFerretera, susLst)
 */

export const navItemsCatTruper = {
  navItemInterno: { link: "/buscar-truper/", texto: "Buscar Truper" },
  navItemInternoUsuario: {
    link: "/catalogo-truper-gratis/",
    texto: "Buscar Truper",
  },
  navItemInternoUsuarioNego: {
    link: "/nego/[nego_slug]/catalogo-truper-gratis/",
    texto: "Buscar Truper",
  },
  navItemInternoCliente: { link: "/catalogo-truper/", texto: "Buscar Truper" },
  navItemInternoClienteNego: {
    link: "/nego/[nego_slug]/catalogo-truper/",
    texto: "Buscar Truper",
  },
}
export const navItemsPuntoVenta = {
  // navItemInterno: {link:"/punto-venta/", texto:"Punto Venta"},
  navItemInterno: {
    link: "/pag/activa-punto-venta-gratis/",
    texto: "Punto Venta",
  },
  // navItemInternoUsuario: {link:"/punto-venta/", texto:"Punto Venta"},
  navItemInternoUsuario: {
    link: "/pag/activa-punto-venta-gratis/",
    texto: "Punto Venta",
  },
  navItemInternoUsuarioNego: {
    link: "/nego/[nego_slug]/punto-venta-gratis/",
    texto: "Punto Venta",
  },
  // navItemInternoCliente: {link:"/punto-venta/", texto:"Punto Venta"},
  navItemInternoCliente: {
    link: "/pag/activa-punto-venta-gratis/",
    texto: "Punto Venta",
  },
  navItemInternoClienteNego: {
    link: "/nego/[nego_slug]/punto-venta/",
    texto: "Punto Venta",
  },
}
export const navItemsCotizador = {
  navItemInterno: {
    link: "/enviar-cotizacion/",
    texto: "Crear Cotizaciones Truper",
  },
  navItemInternoUsuario: {
    link: "/cotizador-gratis/",
    texto: "Crear Cotizaciones Truper",
  },
  navItemInternoUsuarioNego: {
    link: "/nego/[nego_slug]/cotizador-gratis/",
    texto: "Crear Cotizaciones Truper",
  },
  navItemInternoCliente: {
    link: "/cotizador/",
    texto: "Crear Cotizaciones Truper",
  },
  navItemInternoClienteNego: {
    link: "/nego/[nego_slug]/cotizador/",
    texto: "Crear Cotizaciones Truper",
  },
}

export const navItemsCotizaciones = {
  navItemInterno: { link: "/cotizaciones/", texto: "Cotizaciones" },
  navItemInternoUsuario: { link: "/cotizaciones/", texto: "Cotizaciones" },
  navItemInternoUsuarioNego: {
    link: "/nego/[nego_slug]/cotizaciones/",
    texto: "Cotizaciones",
  },
  navItemInternoCliente: { link: "/cotizaciones/", texto: "Cotizaciones" },
  navItemInternoClienteNego: {
    link: "/nego/[nego_slug]/cotizaciones/",
    texto: "Cotizaciones",
  },
}

export const linksCatTruper = {
  linkInterno: "/buscar-truper/",
  linkInternoUsuario: "/catalogo-truper-gratis/",
  linkInternoUsuarioNego: "/nego/[nego_slug]/catalogo-truper-gratis/",
  linkInternoCliente: "/catalogo-truper/",
  linkInternoClienteNego: "/nego/[nego_slug]/catalogo-truper/",
}

export const linksPuntoVenta = {
  // linkInterno: "/punto-venta/",
  linkInterno: "/pag/activa-punto-venta-gratis/",
  // linkInternoUsuario: "/punto-venta/",
  linkInternoUsuario: "/pag/activa-punto-venta-gratis/",
  linkInternoUsuarioNego: "/nego/[nego_slug]/punto-venta-gratis/",
  // linkInternoCliente: "/punto-venta/",
  linkInternoCliente: "/pag/activa-punto-venta-gratis/",
  linkInternoClienteNego: "/nego/[nego_slug]/punto-venta/",
}

export const linksCotizador = {
  linkInterno: "/enviar-cotizacion/",
  linkInternoUsuario: "/cotizador-gratis/",
  linkInternoUsuarioNego: "/nego/[nego_slug]/cotizador-gratis/",
  linkInternoCliente: "/cotizador/",
  linkInternoClienteNego: "/nego/[nego_slug]/cotizador/",
}

export const linksCotizaciones = {
  linkInterno: "/cotizaciones/",
  linkInternoUsuario: "/cotizaciones/",
  linkInternoUsuarioNego: "/nego/[nego_slug]/cotizaciones/",
  linkInternoCliente: "/cotizaciones/",
  linkInternoClienteNego: "/nego/[nego_slug]/cotizaciones/",
}

export const getNavItemCatTruperCal = authContextData => {
  const userInfo = authContextData?.userProperties ?? {}
  // console.log("🚀 ~ file: [pedido_uuid].js:29 ~ PreviewPedidoPage ~ userInfo:", userInfo)

  const navItems = navItemsCatTruper

  const navItemCatTruper = getNavItemInternoCalc(
    navItems,
    userInfo.user_uid,
    userInfo.tieneSuscripcionVigente,
    userInfo.tieneNego,
  )

  return navItemCatTruper
}

export const getNavItemCotizadorCal = authContextData => {
  const userInfo = authContextData?.userProperties ?? {}
  // console.log("🚀 ~ file: [pedido_uuid].js:29 ~ PreviewPedidoPage ~ userInfo:", userInfo)

  const navItems = navItemsCotizador

  const navItemCotizador = getNavItemInternoCalc(
    navItems,
    userInfo.user_uid,
    userInfo.tieneSuscripcionVigente,
    userInfo.tieneNego,
  )

  return navItemCotizador
}

export const getNavItemInternoCalc = (
  {
    navItemInterno,
    navItemInternoUsuario,
    navItemInternoUsuarioNego,
    navItemInternoCliente,
    navItemInternoClienteNego,
  },
  user_uid,
  tieneSuscripcionVigente,
  tieneNego,
) => {
  if (user_uid) {
    if (tieneSuscripcionVigente) {
      if (navItemInternoClienteNego && tieneNego)
        return {
          ...navItemInternoClienteNego,
          link: navItemInternoClienteNego.link.replace(
            "[nego_slug]",
            tieneNego,
          ),
        }
      if (navItemInternoCliente) return navItemInternoCliente
    } else {
      if (navItemInternoUsuarioNego && tieneNego)
        return {
          ...navItemInternoClienteNego,
          link: navItemInternoClienteNego.link.replace(
            "[nego_slug]",
            tieneNego,
          ),
        }

      if (navItemInternoUsuario) return navItemInternoUsuario
    }
  }

  return navItemInterno
}

export const getLinkCatTruperCal = authContextData => {
  const userInfo = authContextData?.userProperties ?? {}
  // console.log("🚀 ~ file: [pedido_uuid].js:29 ~ PreviewPedidoPage ~ userInfo:", userInfo)

  const links = linksCatTruper

  const linkCotizador = getLinkInternoCalc(
    links,
    userInfo.user_uid,
    userInfo.tieneSuscripcionVigente,
    userInfo.tieneNego,
  )

  return linkCotizador
}

export const getLinkPuntoVentaCal = authContextData => {
  const userInfo = authContextData?.userProperties ?? {}
  // console.log("🚀 ~ file: [pedido_uuid].js:29 ~ PreviewPedidoPage ~ userInfo:", userInfo)

  const links = linksPuntoVenta

  const linkCotizador = getLinkInternoCalc(
    links,
    userInfo.user_uid,
    userInfo.tieneSuscripcionVigente,
    userInfo.tieneNego,
  )

  return linkCotizador
}

export const getLinkCotizadorCal = authContextData => {
  const userInfo = authContextData?.userProperties ?? {}
  // console.log("🚀 ~ file: [pedido_uuid].js:29 ~ PreviewPedidoPage ~ userInfo:", userInfo)

  const links = linksCotizador

  const linkCotizador = getLinkInternoCalc(
    links,
    userInfo.user_uid,
    userInfo.tieneSuscripcionVigente,
    userInfo.tieneNego,
  )

  return linkCotizador
}

export const getLinkCotizacionesCal = authContextData => {
  const userInfo = authContextData?.userProperties ?? {}
  // console.log("🚀 ~ file: [pedido_uuid].js:29 ~ PreviewPedidoPage ~ userInfo:", userInfo)

  const links = linksCotizaciones

  const linkCotizador = getLinkInternoCalc(
    links,
    userInfo.user_uid,
    userInfo.tieneSuscripcionVigente,
    userInfo.tieneNego,
  )

  return linkCotizador
}

export const getLinkInternoCalc = (
  {
    linkInterno,
    linkInternoUsuario,
    linkInternoUsuarioNego,
    linkInternoCliente,
    linkInternoClienteNego,
  },
  user_uid,
  tieneSuscripcionVigente,
  tieneNego,
) => {
  if (user_uid) {
    if (tieneSuscripcionVigente) {
      if (linkInternoClienteNego && tieneNego)
        return linkInternoClienteNego.replace("[nego_slug]", tieneNego)

      if (linkInternoCliente) return linkInternoCliente
    } else {
      if (linkInternoUsuarioNego && tieneNego)
        return linkInternoUsuarioNego.replace("[nego_slug]", tieneNego)

      if (linkInternoUsuario) return linkInternoUsuario
    }
  }

  return linkInterno
}

export const getInfoUsuarioSite = authContextData => {
  const {
    user,
    // infoControl,
    infoUsuarios: { suscripciones = {}, negos = {}, membresias = {} } = {},
    // infoUsuariosControl: { tiendas = {} } = {},
  } = authContextData ?? {}

  const suscripcionesLst = Object.values(suscripciones)
  const negosLst = Object.values(negos)
  const membresiasLst = Object.values(membresias)

  // console.debug("🚀 ~ file: siteUtils.js:63 ~ getInfoUsuarioSite ~ getInfoUsuarioSite:", user?.uid)

  const tieneSuscripcionVigente = validarSuscripcionVigenteAF(suscripcionesLst)
  // suscripcionVigente(getSuscripcionVigente(suscripcionesLst))

  const membresiaVigente_COMPRADOR_MAYORISTA =
    getMembresiaVigente_COMPRADOR_MAYORISTA(membresiasLst)

  const tieneNego = negosLst?.[0]?.slug
  const nego = negosLst?.[0]

  const susPorVencer = getSuscripcionVencimientoMesAF(suscripcionesLst, 0)
  const susVencida = tieneSuscripcionVencidaAF(suscripcionesLst)

  return {
    user,
    nombre: user?.displayName,
    foto: user?.photoURL,
    email: user?.email,
    lastLoginAt: user?.lastLoginAt,
    createdAt: user?.createdAt,
    suscripciones,
    suscripcionesLst,
    negos,
    negosLst,
    nego,
    membresias,
    membresiasLst,
    uid: user?.uid,
    user_uid: user?.uid,
    tieneSuscripcionVigente,
    tieneNego,
    susPorVencer,
    susVencida,
    membresiaVigente_COMPRADOR_MAYORISTA,
  }
}

// export const getAniomesActual = getAniomesActual_

// export const getAniomesAjuste = getAniomesAjuste_

// export const getAniomesdiaActual = getAniomesdiaActual_

export const handleKeyDown = (ev, showPopup, setShowPopup) => {
  if (ev.keyCode === 13 && !showPopup) {
    // enter to open
    setShowPopup(true)
  } else if (ev.keyCode === 27 && showPopup) {
    // escape to close
    setShowPopup(false)
  }
}

const addPaddleSubscription = (netlifyID, paddleSubID, callback) => {
  async function postData(url) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ netlifyID: netlifyID, paddleSubID: paddleSubID }),
    })
    return response.json()
  }
  postData("/api/addSub/")
    .then(data => {
      console.log("Success: ", data)
      callback()
    })
    .catch(error => {
      console.log("Error: ", JSON.stringify(error))
    })
}

export const openPaddleCheckout = (user, callback) => {
  const checkoutComplete = data => {
    var checkoutId = data.checkout.id

    Paddle.Order.details(checkoutId, function (data) {
      addPaddleSubscription(user.uid, data.order.subscription_id, callback)
    })
  }
  let options = {
    // product: process.env.PADDLE_PRODUCT_ID,
    product: 16620,
    successCallback: checkoutComplete,
  }
  if (user && user.email) {
    options.email = user.email
  }
  let Paddle = window["Paddle"]
  if (Paddle) {
    Paddle.Checkout.open(options)
  }
}

export const getPaddleSubscription = async netlifyID => {
  async function postData(url) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ netlifyID: netlifyID }),
    })
    return response.json()
  }
  return await postData("/api/getSub/")
}

export const cancelPaddleSubscription = netlifyID => {
  async function postData(url) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ netlifyID: netlifyID }),
    })
    return response.json()
  }
  postData("/api/deleteSub/")
    .then(data => {
      console.log("Success: ", data)
    })
    .catch(error => {
      console.log("Error: ", JSON.stringify(error))
    })
}
