import React, {
  useRef,
  createContext,
  useContext,
  useCallback,
  useSyncExternalStore,
} from "react"

export default function createFastContext(initialState) {
  function useStoreData() {
    const store = useRef(initialState)

    const get = useCallback(() => store.current, [])

    const subscribers = useRef(new Set())

    const set = useCallback(value => {
      store.current =
        typeof value === "function"
          ? { ...store.current, ...value(store.current) }
          : { ...store.current, ...value }
      subscribers.current.forEach(callback => callback())
    }, [])

    const subscribe = useCallback(callback => {
      subscribers.current.add(callback)
      return () => subscribers.current.delete(callback)
    }, [])

    return {
      get,
      set,
      subscribe,
    }
  }

  const StoreContext = createContext(null)

  function Provider({ children }) {
    return (
      <StoreContext.Provider value={useStoreData()}>
        {children}
      </StoreContext.Provider>
    )
  }

  function useStore(selector) {
    const store = useContext(StoreContext)
    if (!store) {
      throw new Error("Store not found")
    }

    const state = useSyncExternalStore(
      store.subscribe,
      () => selector(store.get()),
      () => selector(initialState),
    )

    return [state, store.set]
  }

  function useStoreField(fieldName) {
    const [getter, setter] = useStore(store => store[fieldName])

    return [
      getter,
      value =>
        typeof value === "function"
          ? setter(store => ({
              [fieldName]: value(store[fieldName]),
            }))
          : setter({ [fieldName]: value }),
    ]
  }
  // function useFastContextFields(fieldNames) {
  //   const gettersAndSetters = {}
  //   for (const fieldName of fieldNames) {
  //     const [getter, setter] = useStore(store => store[fieldName])
  //     gettersAndSetters[fieldName] = {
  //       get: getter,
  //       set: value => setter({ [fieldName]: value }),
  //     }
  //   }

  //   return gettersAndSetters
  // }

  return {
    Provider,
    useStore,
    useStoreField,
  }
}
