import { getFechaActualizacionObj, getFechaCreacionActualizacionObj } from "acciondigital-commons/commonUtils"

import { addItemEntity, deleteItemEntity, updateItemEntity } from "acciondigital-basic-theme/src/utils/db/firebase/firebaseDataUtils"

export const banners_area = "page-info"
export const banners_entity = "banners"

export const eliminarBanner = async (siteId, itemKey) => {
    if (itemKey) {
        await deleteItemEntity(siteId, banners_entity, banners_area, itemKey)
    }
}

export const nuevoBanner = async (siteId, values) => {
    const fechaObj = getFechaCreacionActualizacionObj()
    const newData = {
      ...values,
      orden: values.orden * 1,
      ...fechaObj,
    }

    return await addItemEntity(
      siteId,
      banners_entity,
      banners_area,
      undefined,
      newData,
      { includeKey: true }
    )
}

export const duplicarBanner = async (siteId, values) => {
    const {
        key, 
        fechaCreacionInt,
        fechaCreacion,
        fechaCreacionStr,
        fechaActualizacionInt,
        fechaActualizacion,
        fechaActualizacionStr,
        titulo,
        activo,
        orden,
        ...rest
    } = values

    const uuid = crypto.randomUUID().split("-");


    const newData = {
        ...rest,
        activo: false,
        orden: 99,
        titulo:`${titulo} - (DUPLICADO ${uuid[uuid.length-1]})`,
    }

    return await nuevoBanner(siteId, newData)
}

export const actualizarBanner = async (siteId, itemKey, values) => {
    const fechaObj = getFechaActualizacionObj()

    const newData = {
      ...values,
      orden: values.orden * 1,
      ...fechaObj,
    }

    return await updateItemEntity(
      siteId,
      banners_entity,
      banners_area,
      itemKey,
      newData
    )
}