import React from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

import { CardProvider } from "acciondigital-basic-theme/src/components/CartContext"
import AuthProvider from "acciondigital-basic-theme/src/context/AuthContext"

import { SiteContextProvider } from "../context/SiteContext"

import infoSitio from "../utils/infoSitio"
import { getInfoUsuarioSite, getLinkCatTruperCal, getLinkCotizadorCal, getLinkPuntoVentaCal } from "../utils/siteUtils"

const { netlifySiteId: sitio } = infoSitio
// console.log("🚀 ~ file: rootElement.js:11 ~ infoSitio:", infoSitio)

const queryClient = new QueryClient()

const getUserProperties = (authContextData) => {
  // console.log("🚀 ~ file: gatsby-browser.js:49 ~ infoUsuarios", {
  //   infoUsuarios,
  //   infoUsuariosControl,
  //   infoControl,
  // })

  const {
    // user,
    infoUsuarios,
    // infoUsuariosControl,
    infoControl,
  } = authContextData

  const userProperties = getInfoUsuarioSite(authContextData)
  // {
  //   menuItemCatalogo: { link: "/buscar-truper", text: "Buscar Truper" },
  //   menuItemCotizador: {
  //     link: "/enviar-cotizacion",
  //     text: "Crear Cotizaciones Truper",
  //   },
  //   menuItemPuntoVenta: {
  //     // link: "/punto-venta",
  //     link: "/pag/activa-punto-venta-gratis/",
  //     text: "Punto Venta",
  //   },
  //   ...getInfoUsuarioSite(authContextData)
  // }

  const { suscripciones } = infoUsuarios ?? {}
  if (suscripciones) userProperties.tieneSuscripcion = true
  // userProperties.tieneSuscripcion = suscripciones ? true : null
  // if (suscripciones) return { tieneSuscripcion: true }

  const { admin } = infoControl ?? {}
  if (admin) userProperties.esAdmin = true
  // userProperties.esAdmin = admin ? true : null
  // if (admin) return { esAdmin: true }

  // console.log("🚀 ~ file: gatsby-browser.js:66 ~ userProperties", userProperties)

  const props = {...authContextData, userProperties}

  const linkCatTruper = getLinkCatTruperCal(props)
  const linkCotizador = getLinkCotizadorCal(props)
  const linkPuntoVenta = getLinkPuntoVentaCal(props)
  // console.log("🚀 ~ rootElement.js:66 ~ getUserProperties ~ linkCatTruper:", linkCatTruper)

  userProperties.menuItemCatalogo = {
    link: linkCatTruper,
    text: "Buscar Truper",
  }

  userProperties.menuItemCotizador = {
    link: linkCotizador,
    text: "Crear Cotizaciones Truper",
  }

  userProperties.menuItemPuntoVenta = {
    link: linkPuntoVenta,
    text: "Punto Venta",
  }

  console.log("🚀 ~ rootElement.js:81 ~ getUserProperties ~ userProperties:", userProperties)
  return userProperties
  // return undefined
}

export const RootElement = ({ children }) => (
  <AuthProvider sitio={sitio} getUserProperties={getUserProperties}>
    <QueryClientProvider client={queryClient}>
      <SiteContextProvider>
        <CardProvider
          sitio={sitio}
          saveToFirebase={true}
          vigenciaDiasDefault={-1}
        >
          {children}
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </CardProvider>
      </SiteContextProvider>
    </QueryClientProvider>
  </AuthProvider>
)

//   <AuthProvider sitio={sitio} getUserProperties={getUserProperties}>
//     <SiteContextProvider>
//       <CardProvider sitio={sitio} saveToFirebase={true} vigenciaDiasDefault={-1}>
//         {element}
//       </CardProvider>
//     <SiteContextProvider>
//   </AuthProvider>

export default RootElement
