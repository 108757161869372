exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activar-suscripcion-js": () => import("./../../../src/pages/activar-suscripcion.js" /* webpackChunkName: "component---src-pages-activar-suscripcion-js" */),
  "component---src-pages-adm-admon-compras-js": () => import("./../../../src/pages/adm/admon-compras.js" /* webpackChunkName: "component---src-pages-adm-admon-compras-js" */),
  "component---src-pages-adm-admon-membresias-js": () => import("./../../../src/pages/adm/admon-membresias.js" /* webpackChunkName: "component---src-pages-adm-admon-membresias-js" */),
  "component---src-pages-adm-admon-pedidos-js": () => import("./../../../src/pages/adm/admon-pedidos.js" /* webpackChunkName: "component---src-pages-adm-admon-pedidos-js" */),
  "component---src-pages-adm-admon-sus-pendientes-js": () => import("./../../../src/pages/adm/admon-sus-pendientes.js" /* webpackChunkName: "component---src-pages-adm-admon-sus-pendientes-js" */),
  "component---src-pages-adm-admon-suscripciones-js": () => import("./../../../src/pages/adm/admon-suscripciones.js" /* webpackChunkName: "component---src-pages-adm-admon-suscripciones-js" */),
  "component---src-pages-adm-admon-tiendas-js": () => import("./../../../src/pages/adm/admon-tiendas.js" /* webpackChunkName: "component---src-pages-adm-admon-tiendas-js" */),
  "component---src-pages-adm-compras-all-js": () => import("./../../../src/pages/adm/compras-all.js" /* webpackChunkName: "component---src-pages-adm-compras-all-js" */),
  "component---src-pages-adm-direcciones-envio-js": () => import("./../../../src/pages/adm/direcciones-envio.js" /* webpackChunkName: "component---src-pages-adm-direcciones-envio-js" */),
  "component---src-pages-adm-pedidos-all-js": () => import("./../../../src/pages/adm/pedidos-all.js" /* webpackChunkName: "component---src-pages-adm-pedidos-all-js" */),
  "component---src-pages-adm-perfil-envio-nego-js": () => import("./../../../src/pages/adm/perfil-envio-nego.js" /* webpackChunkName: "component---src-pages-adm-perfil-envio-nego-js" */),
  "component---src-pages-adm-seguimiento-compra-admin-compra-id-js": () => import("./../../../src/pages/adm/seguimiento-compra-admin/[compra_id].js" /* webpackChunkName: "component---src-pages-adm-seguimiento-compra-admin-compra-id-js" */),
  "component---src-pages-adm-sus-pendientes-all-js": () => import("./../../../src/pages/adm/sus-pendientes-all.js" /* webpackChunkName: "component---src-pages-adm-sus-pendientes-all-js" */),
  "component---src-pages-adm-suscripciones-all-js": () => import("./../../../src/pages/adm/suscripciones-all.js" /* webpackChunkName: "component---src-pages-adm-suscripciones-all-js" */),
  "component---src-pages-adm-test-pages-js": () => import("./../../../src/pages/adm/test-pages.js" /* webpackChunkName: "component---src-pages-adm-test-pages-js" */),
  "component---src-pages-admon-banners-js": () => import("./../../../src/pages/admon-banners.js" /* webpackChunkName: "component---src-pages-admon-banners-js" */),
  "component---src-pages-admon-popups-js": () => import("./../../../src/pages/admon-popups.js" /* webpackChunkName: "component---src-pages-admon-popups-js" */),
  "component---src-pages-admon-users-js": () => import("./../../../src/pages/admon-users.js" /* webpackChunkName: "component---src-pages-admon-users-js" */),
  "component---src-pages-admon-usuarios-js": () => import("./../../../src/pages/admon-usuarios.js" /* webpackChunkName: "component---src-pages-admon-usuarios-js" */),
  "component---src-pages-admon-usuarios-old-js": () => import("./../../../src/pages/admon-usuarios-old.js" /* webpackChunkName: "component---src-pages-admon-usuarios-old-js" */),
  "component---src-pages-alta-negocio-js": () => import("./../../../src/pages/alta-negocio.js" /* webpackChunkName: "component---src-pages-alta-negocio-js" */),
  "component---src-pages-alta-negocio-old-js": () => import("./../../../src/pages/alta-negocio-old.js" /* webpackChunkName: "component---src-pages-alta-negocio-old-js" */),
  "component---src-pages-archivo-excel-catalogo-ferreteria-js": () => import("./../../../src/pages/archivo-excel-catalogo-ferreteria.js" /* webpackChunkName: "component---src-pages-archivo-excel-catalogo-ferreteria-js" */),
  "component---src-pages-archivo-excel-catalogo-truper-js": () => import("./../../../src/pages/archivo-excel-catalogo-truper.js" /* webpackChunkName: "component---src-pages-archivo-excel-catalogo-truper-js" */),
  "component---src-pages-archivo-excel-promotruper-js": () => import("./../../../src/pages/archivo-excel-promotruper.js" /* webpackChunkName: "component---src-pages-archivo-excel-promotruper-js" */),
  "component---src-pages-buscar-ferreteria-js": () => import("./../../../src/pages/buscar-ferreteria.js" /* webpackChunkName: "component---src-pages-buscar-ferreteria-js" */),
  "component---src-pages-buscar-truper-js": () => import("./../../../src/pages/buscar-truper.js" /* webpackChunkName: "component---src-pages-buscar-truper-js" */),
  "component---src-pages-canasta-compra-js": () => import("./../../../src/pages/canasta-compra.js" /* webpackChunkName: "component---src-pages-canasta-compra-js" */),
  "component---src-pages-catalogo-truper-gratis-js": () => import("./../../../src/pages/catalogo-truper-gratis.js" /* webpackChunkName: "component---src-pages-catalogo-truper-gratis-js" */),
  "component---src-pages-catalogo-truper-js": () => import("./../../../src/pages/catalogo-truper.js" /* webpackChunkName: "component---src-pages-catalogo-truper-js" */),
  "component---src-pages-config-pos-afiliado-id-js": () => import("./../../../src/pages/config-pos/[afiliado_id].js" /* webpackChunkName: "component---src-pages-config-pos-afiliado-id-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-cotizacion-pedido-uuid-js": () => import("./../../../src/pages/cotizacion/[pedido_uuid].js" /* webpackChunkName: "component---src-pages-cotizacion-pedido-uuid-js" */),
  "component---src-pages-cotizaciones-js": () => import("./../../../src/pages/cotizaciones.js" /* webpackChunkName: "component---src-pages-cotizaciones-js" */),
  "component---src-pages-cotizador-gratis-js": () => import("./../../../src/pages/cotizador-gratis.js" /* webpackChunkName: "component---src-pages-cotizador-gratis-js" */),
  "component---src-pages-cotizador-js": () => import("./../../../src/pages/cotizador.js" /* webpackChunkName: "component---src-pages-cotizador-js" */),
  "component---src-pages-crear-suscripcion-js": () => import("./../../../src/pages/crear-suscripcion.js" /* webpackChunkName: "component---src-pages-crear-suscripcion-js" */),
  "component---src-pages-dev-firebase-test-js": () => import("./../../../src/pages/dev/firebase-test.js" /* webpackChunkName: "component---src-pages-dev-firebase-test-js" */),
  "component---src-pages-dev-menu-js": () => import("./../../../src/pages/dev/menu.js" /* webpackChunkName: "component---src-pages-dev-menu-js" */),
  "component---src-pages-dev-page-test-js": () => import("./../../../src/pages/dev/page-test.js" /* webpackChunkName: "component---src-pages-dev-page-test-js" */),
  "component---src-pages-dev-test-config-js": () => import("./../../../src/pages/dev/test-config.js" /* webpackChunkName: "component---src-pages-dev-test-config-js" */),
  "component---src-pages-dev-test-login-js": () => import("./../../../src/pages/dev/test-login.js" /* webpackChunkName: "component---src-pages-dev-test-login-js" */),
  "component---src-pages-dev-test-popup-js": () => import("./../../../src/pages/dev/test-popup.js" /* webpackChunkName: "component---src-pages-dev-test-popup-js" */),
  "component---src-pages-dev-test-sitecontext-js": () => import("./../../../src/pages/dev/test-sitecontext.js" /* webpackChunkName: "component---src-pages-dev-test-sitecontext-js" */),
  "component---src-pages-dev-test-sitemap-js": () => import("./../../../src/pages/dev/test-sitemap.js" /* webpackChunkName: "component---src-pages-dev-test-sitemap-js" */),
  "component---src-pages-dev-test-suscripcion-js": () => import("./../../../src/pages/dev/test-suscripcion.js" /* webpackChunkName: "component---src-pages-dev-test-suscripcion-js" */),
  "component---src-pages-direccion-envio-nego-js": () => import("./../../../src/pages/direccion-envio-nego.js" /* webpackChunkName: "component---src-pages-direccion-envio-nego-js" */),
  "component---src-pages-enviar-cotizacion-js": () => import("./../../../src/pages/enviar-cotizacion.js" /* webpackChunkName: "component---src-pages-enviar-cotizacion-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-fiscal-ticket-js": () => import("./../../../src/pages/info-fiscal-ticket.js" /* webpackChunkName: "component---src-pages-info-fiscal-ticket-js" */),
  "component---src-pages-mi-cuenta-js": () => import("./../../../src/pages/mi-cuenta.js" /* webpackChunkName: "component---src-pages-mi-cuenta-js" */),
  "component---src-pages-mis-compras-js": () => import("./../../../src/pages/mis-compras.js" /* webpackChunkName: "component---src-pages-mis-compras-js" */),
  "component---src-pages-nego-afiliado-slug-canasta-compra-js": () => import("./../../../src/pages/nego/[afiliado_slug]/canasta-compra.js" /* webpackChunkName: "component---src-pages-nego-afiliado-slug-canasta-compra-js" */),
  "component---src-pages-nego-afiliado-slug-catalogo-truper-gratis-js": () => import("./../../../src/pages/nego/[afiliado_slug]/catalogo-truper-gratis.js" /* webpackChunkName: "component---src-pages-nego-afiliado-slug-catalogo-truper-gratis-js" */),
  "component---src-pages-nego-afiliado-slug-catalogo-truper-js": () => import("./../../../src/pages/nego/[afiliado_slug]/catalogo-truper.js" /* webpackChunkName: "component---src-pages-nego-afiliado-slug-catalogo-truper-js" */),
  "component---src-pages-nego-afiliado-slug-cotizacion-pedido-uuid-js": () => import("./../../../src/pages/nego/[afiliado_slug]/cotizacion/[pedido_uuid].js" /* webpackChunkName: "component---src-pages-nego-afiliado-slug-cotizacion-pedido-uuid-js" */),
  "component---src-pages-nego-afiliado-slug-cotizador-gratis-js": () => import("./../../../src/pages/nego/[afiliado_slug]/cotizador-gratis.js" /* webpackChunkName: "component---src-pages-nego-afiliado-slug-cotizador-gratis-js" */),
  "component---src-pages-nego-afiliado-slug-cotizador-js": () => import("./../../../src/pages/nego/[afiliado_slug]/cotizador.js" /* webpackChunkName: "component---src-pages-nego-afiliado-slug-cotizador-js" */),
  "component---src-pages-nego-afiliado-slug-punto-venta-gratis-js": () => import("./../../../src/pages/nego/[afiliado_slug]/punto-venta-gratis.js" /* webpackChunkName: "component---src-pages-nego-afiliado-slug-punto-venta-gratis-js" */),
  "component---src-pages-nego-afiliado-slug-punto-venta-js": () => import("./../../../src/pages/nego/[afiliado_slug]/punto-venta.js" /* webpackChunkName: "component---src-pages-nego-afiliado-slug-punto-venta-js" */),
  "component---src-pages-nego-afiliado-slug-ticket-js": () => import("./../../../src/pages/nego/[afiliado_slug]/ticket.js" /* webpackChunkName: "component---src-pages-nego-afiliado-slug-ticket-js" */),
  "component---src-pages-nego-nego-slug-js": () => import("./../../../src/pages/nego/[nego_slug].js" /* webpackChunkName: "component---src-pages-nego-nego-slug-js" */),
  "component---src-pages-pag-activa-punto-venta-gratis-js": () => import("./../../../src/pages/pag/activa-punto-venta-gratis.js" /* webpackChunkName: "component---src-pages-pag-activa-punto-venta-gratis-js" */),
  "component---src-pages-pag-crear-suscripcion-gratuita-js": () => import("./../../../src/pages/pag/crear-suscripcion-gratuita.js" /* webpackChunkName: "component---src-pages-pag-crear-suscripcion-gratuita-js" */),
  "component---src-pages-pag-quiero-ser-comprador-mayorista-js": () => import("./../../../src/pages/pag/quiero-ser-comprador-mayorista.js" /* webpackChunkName: "component---src-pages-pag-quiero-ser-comprador-mayorista-js" */),
  "component---src-pages-pag-registra-tu-negocio-js": () => import("./../../../src/pages/pag/registra-tu-negocio.js" /* webpackChunkName: "component---src-pages-pag-registra-tu-negocio-js" */),
  "component---src-pages-pedido-compra-js": () => import("./../../../src/pages/pedido-compra.js" /* webpackChunkName: "component---src-pages-pedido-compra-js" */),
  "component---src-pages-prod-[codigo]-js": () => import("./../../../src/pages/prod/[codigo].js" /* webpackChunkName: "component---src-pages-prod-[codigo]-js" */),
  "component---src-pages-producto-detalle-[codigo]-js": () => import("./../../../src/pages/producto-detalle/[codigo].js" /* webpackChunkName: "component---src-pages-producto-detalle-[codigo]-js" */),
  "component---src-pages-promotruper-anteriores-js": () => import("./../../../src/pages/promotruper-anteriores.js" /* webpackChunkName: "component---src-pages-promotruper-anteriores-js" */),
  "component---src-pages-registro-js": () => import("./../../../src/pages/registro.js" /* webpackChunkName: "component---src-pages-registro-js" */),
  "component---src-pages-seguimiento-compra-compra-id-js": () => import("./../../../src/pages/seguimiento-compra/[compra_id].js" /* webpackChunkName: "component---src-pages-seguimiento-compra-compra-id-js" */),
  "component---src-pages-ticket-js": () => import("./../../../src/pages/ticket.js" /* webpackChunkName: "component---src-pages-ticket-js" */),
  "component---src-pages-ticket-venta-venta-uid-js": () => import("./../../../src/pages/ticket-venta/[venta_uid].js" /* webpackChunkName: "component---src-pages-ticket-venta-venta-uid-js" */),
  "component---src-pages-venta-detalle-venta-uid-js": () => import("./../../../src/pages/venta-detalle/[venta_uid].js" /* webpackChunkName: "component---src-pages-venta-detalle-venta-uid-js" */),
  "component---src-pages-ventas-js": () => import("./../../../src/pages/ventas.js" /* webpackChunkName: "component---src-pages-ventas-js" */),
  "component---src-pages-ver-pedido-pedido-uuid-js": () => import("./../../../src/pages/ver-pedido/[pedido_uuid].js" /* webpackChunkName: "component---src-pages-ver-pedido-pedido-uuid-js" */),
  "component---src-templates-noticias-paginas-js": () => import("./../../../src/templates/NoticiasPaginas.js" /* webpackChunkName: "component---src-templates-noticias-paginas-js" */),
  "component---src-templates-prods-alta-volatilidad-template-js": () => import("./../../../src/templates/ProdsAltaVolatilidadTemplate.js" /* webpackChunkName: "component---src-templates-prods-alta-volatilidad-template-js" */),
  "component---src-templates-promotruper-template-js": () => import("./../../../src/templates/PromotruperTemplate.js" /* webpackChunkName: "component---src-templates-promotruper-template-js" */),
  "component---src-templates-publicacion-noticia-js": () => import("./../../../src/templates/PublicacionNoticia.js" /* webpackChunkName: "component---src-templates-publicacion-noticia-js" */),
  "component---src-templates-recursos-paginas-js": () => import("./../../../src/templates/RecursosPaginas.js" /* webpackChunkName: "component---src-templates-recursos-paginas-js" */)
}

