import { useCallback, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import firebase from "acciondigital-basic-theme/src/utils/get-firebase-instance"

import useSiteMetadata from "acciondigital-basic-theme/src/hooks/useSiteMetadata"

import createFastContext from "../utils/createFastContext"
import { getBasicSortAsc } from "acciondigital-basic-theme/src/utils/commonUtils"
import { banners_area, banners_entity } from "../utils/db/bannersUtils"

const { Provider: SiteContextProvider, useStore: useSiteStore, useStoreField: useSiteStoreField } =
  createFastContext({
    first: "",
    last: "",
    bannerInterval: 20000,
    bannerIndex: 0,
    menuItemCatalogo: { link: "/buscar-truper", text: "Buscar Truper" },
    menuItemCotizador: {
      link: "/enviar-cotizacion",
      text: "Crear Cotizaciones Truper",
    },
    menuItemPuntoVenta: {
      // link: "/punto-venta",
      link: "/pag/activa-punto-venta-gratis/",
      text: "Punto Venta",
    },
  })

const useSiteBanner = () => {
  const bannerCero = useBannerCero()
  const [banners = bannerCero ? [bannerCero] : [], setStore] = useSiteStore(
    store => store["banners"],
  )
  const [bannerInterval] = useSiteStore(store => store["bannerInterval"])
  const [bannerIndex] = useSiteStore(store => store["bannerIndex"])

  const { siteId } = useSiteMetadata()

  const setBanners = val => setStore({ banners: val })

  const bannerIndexRef = useRef(bannerIndex)

  const accion = useCallback(() => {
    const newIndex = bannerIndexRef.current + 1

    if (newIndex >= banners.length) {
      setStore({ bannerIndex: 0 })
      bannerIndexRef.current = 0
    } else {
      setStore({ bannerIndex: newIndex })
      bannerIndexRef.current = newIndex
    }
  }, [banners, setStore])

  useEffect(() => {
    // console.log("🚀 ~ file: SiteContext.js ~ line 56 ~ useEffect ~ useEffect")
    const interval = setInterval(() => {
      accion()
      //   console.log("🚀 ~ file: SiteContext.js ~ line 59 ~ interval ~ accion")
    }, bannerInterval)

    return () => clearInterval(interval)
  }, [bannerInterval, accion])

  useEffect(() => {
    const refStr = `/app/${siteId}/${banners_area}/${banners_entity}`
    const ref = firebase.database().ref(refStr)
    const listener = snapshot => {
      const datos = snapshot.val()
      if (datos) {
        const items = Object.entries(datos).map(([key, value]) => {
          return { ...value, key }
        })
        // items.sort((b, a) => (a.orden==null ? -1 : b.orden==null ? 1 : b.orden - a.orden))
        items.sort(getBasicSortAsc("orden"))
        // console.log("🚀 ~ file: SiteContext.js:139 ~ listener ~ items:", items)

        // console.log(
        //   "AdmonProductosPage listener = snapshot setUsuarios(usus) - usus.length " +
        //     usus.length
        // )
        // items.unshift(bannerDefault)
        setBanners(items.filter((val, i) => i === 0 || val.activo))
      } else {
        // console.log("AdmonProductosPage listener = snapshot setUsuarios([]) []")
        setBanners(bannerCero ? [bannerCero] : [])
      }
    }

    ref.on("value", listener)
    return () => ref.off("value", listener)
    // }
  }, [siteId])

  return { bannerIndex, banners }
}

const useBannerCero = () => {
  const { allBanner } = useStaticQuery(graphql`
    query {
      allBanner(filter: { orden: { eq: 0 } }) {
        nodes {
          titulo
          orden
          link
          textoBoton
          textoFooter
          promoId
          promoDescripcion
          prodId
          prodDescripcion
        }
      }
    }
  `)
  return allBanner?.nodes?.[0]
}

export { SiteContextProvider, useSiteStore, useSiteStoreField, useSiteBanner }
